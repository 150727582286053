import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import store from "./state/store";
import { vMaska } from "maska";
import "@vueform/multiselect/themes/default.css"

import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import CKEditor from '@ckeditor/ckeditor5-vue';
import BootstrapVueNext from 'bootstrap-vue-next';

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import './assets/scss/app.scss';
import axios from './plugins/axios';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

declare global {
    interface Window {
        Echo:any;
        Pusher: any;
    }
}

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER ?? 'mt1',
    wsHost: process.env.VUE_APP_PUSHER_HOST ? process.env.VUE_APP_PUSHER_HOST : `ws-${process.env.VUE_APP_PUSHER_APP_CLUSTER}.pusher.com`,
    //wsHost: process.env.VUE_APP_PUSHER_HOST ? process.env.VUE_APP_PUSHER_HOST.replace(/\/$/, '') : window.location.hostname,
    wsPort: process.env.VUE_APP_PUSHER_PORT ?? 80,
    wssPort: process.env.VUE_APP_PUSHER_PORT ?? 443,
    forceTLS: (window.location.protocol == 'https:'),
    disableStatus: true
});

window.Echo.connector.pusher.connection.bind('connected', (payload) => {
    console.log('WS Connected');
});


export const vueInstance = createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .use(i18n)
    .directive("maska", vMaska)
    .use(CKEditor)
    .use(axios, {
        baseUrl: 'http://' + process.env.VUE_APP_API_BASE_URL || 'http://localhost/api',
        token: localStorage.getItem('jwt')
    })
    .use(vClickOutside)
    .component('VueDatePicker', VueDatePicker)
    .mount('#app');