import Vue from 'vue';
import axios, { AxiosInstance, AxiosResponse } from "axios";
import {Response} from "@/services/Response";
import Swal from "sweetalert2";
import { toast } from "vue3-toastify";
import i18n from '@/i18n';

const baseURL = process.env.VUE_APP_API_BASE_URL;
const apiURL = "/api/v1";

const API: AxiosInstance = axios.create({
  baseURL: baseURL + apiURL,
});

async function getHeaderConfig(config) {
  switch (config) {
    case "JWT": {
      return {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
      };
    }
    default: {
      return {};
    }
  }
}

const GET = async (url, data = {}, config = "JWT"): Promise<Response> => {
  const result = await API.get(url, { ...data, ...await getHeaderConfig(config) });
  return result.data;
};

const POST = async (url, data, config = "JWT"): Promise<Response> => {
  const result = await API.post(url, data, await getHeaderConfig(config));
  return result.data;
};

const PUT = async (url, data, config = "JWT"): Promise<Response> => {
  const result = await API.put(url, data, await getHeaderConfig(config));
  return result.data;
};

const DELETE = async (url, data, config = "JWT"): Promise<Response> => {
  const result = await API.delete(url, { ...data, ...await getHeaderConfig(config) });
  return result.data;
};

const AUTHJWT = async (  data,  url = "/oauth/token",  config = ""): Promise<AxiosResponse> => {
  const AUTHAPI: AxiosInstance = axios.create({
    baseURL: baseURL,
  });
  const result = await AUTHAPI.post(url, data, await getHeaderConfig(config));
  localStorage.setItem("jwt", result.data.access_token);
  API.defaults.headers.common["authorization"] = "Bearer " + result.data.access_token;
  return result;
};

const HandleError = (errorResponse) => {
  // Handle error response
  if (errorResponse.response && errorResponse.response.status === 422) {
    // Laravel validation error response
    const errors = errorResponse.response.data.result;
    

    let errorMessage = '<div style="text-align: left;">';
    
    if(Array.isArray(errors) || typeof errors === 'object'){
      for (const [key, messages] of Object.entries(errors)) {
        if (Array.isArray(messages)) {
          messages.forEach(message => {
              errorMessage += `<strong>${key}</strong>: ${message}<br>`;
          });
        }
        
        
      }
    }else{
      errorMessage += `<strong>${errorResponse.response.data.message}<br>`;
    }
    
    errorMessage += '</div>';

    // Display errors using SweetAlert
    Swal.fire({
      icon: 'error',
      title: 'Validation Error!',
      html: errorMessage
    });
  } else {
    let message = 'Something went wrong! Please try again';
    if("msg" in errorResponse){
      message = errorResponse["msg"];
    }
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message
    });
  }
};

const HandleSuccess = (response) => {
  let message =  i18n.global.t('t-general-save-success');
  
  if (response.data?.result) {
    message = response.data.result;
  }else if (response.message) {
    message = response.message;
  }
  toast(message, {
    autoClose: 3000,
    type: toast.TYPE.SUCCESS,
  });
};

export { AUTHJWT, GET, POST, PUT, DELETE, HandleError, HandleSuccess};
