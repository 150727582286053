/**
 * Convert a date to UTC.
 * @param {Date} date - The date to convert.
 * @returns {Date} The converted date in UTC.
 */
function convertToUTC(date) {      
  const localDate = new Date();
  localDate.setHours(date.hours);
  localDate.setMinutes(date.minutes);
  localDate.setSeconds(date.seconds);

  // Get timezone offset in minutes
  const timezoneOffsetMinutes = localDate.getTimezoneOffset();

  // Calculate UTC time in milliseconds since Unix epoch
  const utcTimeInMillis = localDate.getTime() + (timezoneOffsetMinutes * 60000);

  const utcDate = new Date(utcTimeInMillis);
  const utclHours = utcDate.getHours().toString().padStart(2, '0');
  const utcMinutes = utcDate.getMinutes().toString().padStart(2, '0');
  const utcSeconds = utcDate.getSeconds().toString().padStart(2, '0');     
   
  return {
      hours: utclHours,
      minutes: utcMinutes,
      seconds: parseInt(utcSeconds)
  };
}

/**
 * Convert a date to local time zone of the end user.
 * @param {Date} date - The date to convert.
 * @returns {Date} The converted date in local timezone.
 */
function convertUtcToLocal(date) {
  const utcDate = new Date();
  utcDate.setUTCHours(date.hours);
  utcDate.setUTCMinutes(date.minutes);
  utcDate.setUTCSeconds(date.seconds);  
  return {
      hours: utcDate.getHours(),
      minutes: utcDate.getMinutes(),
      seconds: utcDate.getSeconds()
  };
}

function getNowForTimePicker() {
  const nowDate = new Date();
  return {
    hours: nowDate.getHours().toString().padStart(2, '0'),
    minutes: nowDate.getMinutes().toString().padStart(2, '0'),
    seconds: nowDate.getSeconds().toString().padStart(2, '0')     
};
}
  
  export { convertToUTC, convertUtcToLocal, getNowForTimePicker } ;