import {edit, formCreate, storeProduct} from "@/services/ProductService";
import {getPluginVersions, getSoftwarePlugins} from "@/services/PluginService";
import { getNowForTimePicker, convertToUTC } from "@/helpers/timeZoneHelper";

export const product = {
    namespaced: true,
    state: {
        formCreate: {
            software: [],
            plugins: [],
            customers: [],
            types: []
        },
        form: {
            software_id: '',
            software_name: '',
            supported_version: '',
            supported_version_type: 1,
            development_schedule_frequency: '',
            development_schedule_time: convertToUTC(getNowForTimePicker()),
            staging_schedule_frequency: '',
            staging_schedule_time: convertToUTC(getNowForTimePicker()),
            production_schedule_frequency: '',
            production_schedule_time: convertToUTC(getNowForTimePicker()),
            product_name: '',
            selected_plugins: [],
            plugin_versions: [],
            product_price: '',
            price_per_user: '',
            availability: 'private',
            customer: null,
            price_increase: '',
            maintainer_id: ''
        },
        is_product_name_available: null
    },
    getters: {
        getFormCreate(state) {
            return state.formCreate;
        }
    },
    mutations: {
        CHANGE_FORM_CREATE(state, data): void {
            state.formCreate = data;
        },
        UPDATE_SUPPORTED_VERSION_TYPE(state, value) {
            state.form.supported_version_type = value;
        },
        UPDATE_SOFTWARE_ID(state, value) {
            state.form.software_id = value;
        },
        UPDATE_SOFTWARE_NAME(state, value) {
            state.form.software_name = value;
        },
        UPDATE_SUPPORTED_VERSION(state, value) {
            state.form.supported_version = value;
        },
        UPDATE_DEVELOPMENT_SCHEDULE_FREQUENCY(state, value) {
            state.form.development_schedule_frequency = value;
        },
        UPDATE_DEVELOPMENT_RESTART_FREQUENCY(state, developmentRestartFrequency) {
            state.form.development_restart_frequency = developmentRestartFrequency;
        },
        UPDATE_DEVELOPMENT_SCHEDULE_TIME(state, value) {
            state.form.development_schedule_time = value;
        },
        UPDATE_DEVELOPMENT_RESTART_TIME(state, developmentRestartTime) {
            state.form.development_restart_time = developmentRestartTime;
        },
        UPDATE_DEVELOPMENT_RESTART_ONCE_BUILT(state, value) {
            state.form.development_restart_once_built = value;
        },
        UPDATE_STAGING_SCHEDULE_FREQUENCY(state, value) {
            state.form.staging_schedule_frequency = value;
        },
        UPDATE_STAGING_RESTART_FREQUENCY(state, stagingRestartFrequency) {
            state.form.staging_restart_frequency = stagingRestartFrequency;
        },
        UPDATE_STAGING_SCHEDULE_TIME(state, value) {
            state.form.staging_schedule_time = value;
        },
        UPDATE_STAGING_RESTART_TIME(state, stagingRestartTime) {
            state.form.staging_restart_time = stagingRestartTime;
        },
        UPDATE_STAGING_RESTART_ONCE_BUILT(state, value) {
            state.form.staging_restart_once_built = value;
        },
        UPDATE_PRODUCTION_SCHEDULE_FREQUENCY(state, value) {
            state.form.production_schedule_frequency = value;
        },
        UPDATE_PRODUCTION_RESTART_FREQUENCY(state, productionRestartFrequency) {
            state.form.production_restart_frequency = productionRestartFrequency;
        },
        UPDATE_PRODUCTION_SCHEDULE_TIME(state, value) {
            state.form.production_schedule_time = value;
        },
        UPDATE_PRODUCTION_RESTART_TIME(state, productionRestartTime) {
            state.form.production_restart_time = productionRestartTime;
        },
        UPDATE_PRODUCTION_RESTART_ONCE_BUILT(state, value) {
            state.form.production_restart_once_built = value;
        },
        UPDATE_PRODUCT_NAME(state, value) {
            state.form.product_name = value;
        },
        UPDATE_PRODUCT_NAME_AVAILABILITY(state, value) {
            state.is_product_name_available = value;
        },
        UPDATE_SELECTED_PLUGINS(state, value) {
            state.form.selected_plugins = value;
        },
        UPDATE_PLUGIN_VERSIONS(state, value) {
            state.form.plugin_versions = value;
        },
        DETACH_PLUGIN_VERSIONS(state, pluginId): void {
            const selectedPlugin =  state.formCreate.plugins.find((plugin) => plugin.id == pluginId);
            selectedPlugin.plugin_versions = [];
        },
        UPDATE_PRODUCT_PRICE(state, value) {
            state.form.product_price = value;
        },
        UPDATE_PRICE_PER_USER(state, value) {
            state.form.price_per_user = value;
        },
        UPDATE_AVAILABILITY(state, value) {
            state.form.availability = value;
        },
        UPDATE_CUSTOMER(state, value) {
            state.form.customer = value;
        },
        UPDATE_PRICE_INCREASE(state, value) {
            state.form.price_increase = value;
        },
        UPDATE_MAINTAINER_ID(state, value) {
            state.form.maintainer_id = value;
        },
        UPDATE_PLUGINS(state, { id, field, value }) {
            if(state.formCreate.plugins){
                state.formCreate.plugins.find((plugin) => plugin.id === id)[field] = value;

            }
        },
        SET_PLUGINS(state, value) {
            state.formCreate.plugins = value;
        },
        RESET_STATE(state, value) {
            Object.assign(state.form, {
                software_id: '',
                software_name: '',
                supported_version: '',
                supported_version_type: 1,
                development_schedule_frequency: '',
                development_schedule_time: convertToUTC(getNowForTimePicker()),
                staging_schedule_frequency: '',
                staging_schedule_time: convertToUTC(getNowForTimePicker()),
                production_schedule_frequency: '',
                production_schedule_time: convertToUTC(getNowForTimePicker()),
                product_name: '',
                selected_plugins: [],
                availability: 'private',
                customer: null,
                maintainer_id: ''
            });
            state.is_product_name_available = null;
        }
    },
    actions: {
        async fetchFormCreate({ commit }): Promise<any> {
            const response = await formCreate();
            const result = response.result;
            commit('CHANGE_FORM_CREATE', result);
        },
        updateSupportedVersionType({ commit }, value) {
            commit('UPDATE_SUPPORTED_VERSION_TYPE', value);
        },
        async fetchPlugins({ commit, state }, params): Promise<any> {
            const response = await getSoftwarePlugins(
                params.sortBy, 
                params.sortDesc, 
                params.filter, 
                params.softwareId, 
                params.supported_version_type, 
                params.version
            );
            commit('SET_PLUGINS', response.result);
        },
        async updatePlugins({ commit }, { id, field, value }) {
            commit('UPDATE_PLUGINS', { id, field, value });
        },
        async fetchPluginVersions({ commit, state }, params): Promise<any> {
            const response = await getPluginVersions(params.pluginId, params.moodleVersion, params.softwareId, params.softwareVersionType);
            commit('UPDATE_PLUGINS', { id:params.pluginId, field:'branch_versions', value: response.branches });
            commit('UPDATE_PLUGINS', { id:params.pluginId, field:'tag_versions', value: response.tags });
        },
        async detachPluginVersions({ commit, state }, params): Promise<any> {
            commit('DETACH_PLUGIN_VERSIONS', params.pluginId);
        },
        updateSupportedVersion({ commit }, value) {
            commit('UPDATE_SUPPORTED_VERSION', value);
        },
        updateDevelopmentScheduleFrequency({ commit }, value) {
            commit('UPDATE_DEVELOPMENT_SCHEDULE_FREQUENCY', value);
        },
        updateDevelopmentRestartFrequency({ commit }, developmentRestartFrequency) {
            commit('UPDATE_DEVELOPMENT_RESTART_FREQUENCY', developmentRestartFrequency);
        },
        updateDevelopmentScheduleTime({ commit }, value) {
            commit('UPDATE_DEVELOPMENT_SCHEDULE_TIME', value);
        },
        updateDevelopmentRestartTime({ commit }, developmentRestartTime) {
            commit('UPDATE_DEVELOPMENT_RESTART_TIME', developmentRestartTime);
        },
        updateDevelopmentRestartOnceBuilt({ commit }, value) {
            commit('UPDATE_DEVELOPMENT_RESTART_ONCE_BUILT', value);
        },
        updateStagingScheduleFrequency({ commit }, value) {
            commit('UPDATE_STAGING_SCHEDULE_FREQUENCY', value);
        },
        updateStagingRestartFrequency({ commit }, stagingRestartFrequency) {
            commit('UPDATE_STAGING_RESTART_FREQUENCY', stagingRestartFrequency);
        },
        updateStagingScheduleTime({ commit }, value) {
            commit('UPDATE_STAGING_SCHEDULE_TIME', value);
        },
        updateStagingRestartTime({ commit }, stagingRestartTimeName) {
            commit('UPDATE_STAGING_RESTART_TIME', stagingRestartTimeName);
        },
        updateStagingRestartOnceBuilt({ commit }, value) {
            commit('UPDATE_STAGING_RESTART_ONCE_BUILT', value);
        },
        updateProductionScheduleFrequency({ commit }, value) {
            commit('UPDATE_PRODUCTION_SCHEDULE_FREQUENCY', value);
        },
        updateProductionRestartFrequency({ commit }, productionRestartFrequency) {
            commit('UPDATE_PRODUCTION_RESTART_FREQUENCY', productionRestartFrequency);
        },
        updateProductionScheduleTime({ commit }, value) {
            commit('UPDATE_PRODUCTION_SCHEDULE_TIME', value);
        },
        updateProductionRestartTime({ commit }, productionRestartTime) {
            commit('UPDATE_PRODUCTION_RESTART_TIME', productionRestartTime);
        },
        updateProductionRestartOnceBuilt({ commit }, value) {
            commit('UPDATE_PRODUCTION_RESTART_ONCE_BUILT', value);
        },
        updateProductName({ commit }, value) {
            commit('UPDATE_PRODUCT_NAME', value);
        },
        updateProductNameAvailability({ commit }, value) {
            commit('UPDATE_PRODUCT_NAME_AVAILABILITY', value);
        },
        updateSelectedPlugins({ commit }, value) {
            commit('UPDATE_SELECTED_PLUGINS', value);
        },
        updatePluginVersions({ commit }, value) {
            commit('UPDATE_PLUGIN_VERSIONS', value);
        },
        updateProductPrice({ commit }, value) {
            commit('UPDATE_PRODUCT_PRICE', value);
        },
        updatePricePerUser({ commit }, value) {
            commit('UPDATE_PRICE_PER_USER', value);
        },
        updateAvailability({ commit }, value) {
            commit('UPDATE_AVAILABILITY', value);
        },
        updateCustomer({ commit }, value) {
            commit('UPDATE_CUSTOMER', value);
        },
        updatePriceIncrease({ commit }, value) {
            commit('UPDATE_PRICE_INCREASE', value);
        },
        updateMaintainerId({ commit }, value) {
            commit('UPDATE_MAINTAINER_ID', value);
        },
        updateSoftwareId({ commit, state }, value) {
            commit('UPDATE_SOFTWARE_ID', value);
            const software =  state.formCreate.software.find((software) => software.id == value);
            commit('UPDATE_SOFTWARE_NAME', software.name);

        },
        async edit({ commit, state }, params): Promise<any> {
            const response = await edit(params.id);
            commit('CHANGE_FORM', response);
        },
        reset({ commit }){
            commit('RESET_STATE');
        },
        async storeProduct({ commit, state }): Promise<any> {
            const data ={ ...state.form};
            const selectedPlugins = state.formCreate.plugins
            .filter(plugin => plugin.included)
            .map(plugin => ({
                id: plugin.id,
                selected_version: plugin.selected_version,
                selected_version_type: plugin.selected_version_type,
                git_url: plugin.git_url
            }));
            data.plugins = selectedPlugins;
            return await storeProduct(data);
        },
    }
}